<template>
    <v-container>
        <v-row>
            <v-col v-for="plan in plans" :key="plan.level" cols="12" md="4">
                <v-card class="pa-2">
                    <v-card-title class="headline">{{ plan.level }}</v-card-title>
                    <v-card-subtitle class="font-weight-bold">${{ plan.price }} / month</v-card-subtitle>
                    <v-list-item-group v-if="plan.features.length">
                        <v-list-item v-for="(feature, index) in plan.features" :key="index">
                            <v-list-item-content>
                                <v-list-item-title>{{ feature }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <v-icon color="green">mdi-check</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list-item-group>
                </v-card>
            </v-col>
        </v-row>
        <v-card-text>
            When you register on this site, you will automatically subscribe to the free plan. You can upgrade your plan
            from your account page.
        </v-card-text>
        <v-btn v-if="isUserAuthenticated" color="primary" @click="goToAccount">
            Go to Account
        </v-btn>

        <v-btn v-else color="secondary" @click="goToRegister">
            Register
        </v-btn>
    </v-container>
</template>

<script>
import axios from 'axios';
import { mapGetters } from "vuex";

export default {
    data: () => ({
        plans: [
            {
                level: 'Free',
                price: 0,
                features: ['5 Uses per Month'],
            },
            {
                level: 'Pro',
                price: 5,
                features: ['100 Uses per Month'],
            },
            {
                level: 'Premium',
                price: 10,
                features: ['300 Uses per Month'],
            },
        ],
    }),
    computed: {
        ...mapGetters(["isUserAuthenticated"]),
    },
    methods: {
        goToAccount() {
            this.$router.push('/account');
        },
        goToRegister() {
            this.$router.push('/register');
        },
    },
};
</script>

<style scoped>
.v-card {
    text-align: center;
    border: 1px solid #b9b9b9;
    border-radius: 8px;
    transition: all 0.3s;
}

.v-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>
